.splash-screen {
  width: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
}

.splash-screen .loading-dot {
  font-size: 150px;
  margin-top: -80px;
  position: relative;
  animation: ease-in-out infinite alternate;
  animation-name: run;
  animation-duration: 1.2s;
}

@keyframes run {
  0% {
    left: -90px;
    color: #eee;
  }
  50% {
    color: #c5281c;
  }
  100% {
    left: 90px;
    color: #eee;
  }
}
