.arrowSt {
  animation: animate infinite 0.8s alternate ease-in-out;
  transition: opacity 0.3s ease;
}

@keyframes animate {
  0% {
    transform: translateY(-15px);
  }
  50% {
  }
  100% {
    transform: translateY(0px);
  }
}
