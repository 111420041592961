html {
  height: 100%;
}

body {
  background-color: '#ff352d';
  height: 100%;
  display: flex;
  margin: 0;
  padding: 0;
}
body #root {
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
}

@media (min-height: 770px) and (min-width: 650px) {
  /* body #root {
    background-image: url('/images/fondo-general.jpg');
  } */
  body #layout {
    border-color: rgb(158, 54, 103);
    box-shadow: 0px 4px 15px 5px;
    border-radius: 5px;
    /* border-style: solid;
    border-width: 1px; */
  }
}

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body {
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
  padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

.MuiPickersDay-current {
  color: #649544 !important;
}

/* Fonts */

@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/fonts/HelveticaNeue-01.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueBold';
  src: url('./assets/fonts/HelveticaNeue-Bold-02.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueBoldItalic';
  src: url('./assets/fonts/HelveticaNeue-BoldItalic-04.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueCondensedBlack';
  src: url('./assets/fonts/HelveticaNeue-CondensedBlack-10.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueCondensedBold';
  src: url('./assets/fonts/HelveticaNeue-CondensedBold-05.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueItalic';
  src: url('./assets/fonts/HelveticaNeue-Italic-03.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueLight';
  src: url('./assets/fonts/HelveticaNeue-Light-08.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueLightItalic';
  src: url('./assets/fonts/HelveticaNeue-LightItalic-09.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueMedium';
  src: url('./assets/fonts/HelveticaNeue-Medium-11.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueMediumItalic';
  src: url('./assets/fonts/HelveticaNeue-MediumItalic-12.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueThin';
  src: url('./assets/fonts/HelveticaNeue-Thin-13.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueThinItalic';
  src: url('./assets/fonts/HelveticaNeue-ThinItalic-14.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueUltraLight';
  src: url('./assets/fonts/HelveticaNeue-UltraLight-06.ttf') format('truetype');
}
@font-face {
  font-family: 'HelveticaNeueUltraLightItalic';
  src: url('./assets/fonts/HelveticaNeue-UltraLightItalic-07.ttf') format('truetype');
}

/* Fix ---------------------------------------------------------------------*/

/* IMPORTANTE: Fix Safari height on flex */
div {
  flex-shrink: 0;
}

button:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

td {
  padding: 5px;
}

.tippy-box {
  background-color: white !important;
}
.tippy-arrow {
  color: white !important;
}

svg:focus {
  outline: none;
}
